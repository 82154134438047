/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "assets/demo/flags/flags.css";
@import "./assets/theme/theme-padrao.scss";
@import "./assets/sass/layout/variables";
@import "./assets/sass/theme/theme";

.combo-filtro-software .p-dropdown-label {
  padding: 12px;
  font-size: 14px;
}

:host ::ng-deep {
  
  .p-table .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #f7f7f7 #f7f7f7;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #f7f7f7;
}

*::-webkit-scrollbar-thumb {
  border: 3px solid #e8e8e8;
  background-color: #e9e9e9;
  border-radius: 7px;
}

@media screen and (max-width: 40rem) {
  :host ::ng-deep {
    .p-datatable {
      &.p-datatable-responsive-demo {
        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
          display: none !important;
        }

        .p-datatable-tbody > tr > td {
          text-align: left;
          display: block;
          width: 100%;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4em 1em -0.4em -0.4rem;
            font-weight: bold;
          }

          &:last-child {
            border-bottom: 1px solid var(--surface-d);
          }
        }
      }
    }
  }
}

cdk-virtual-scroll-viewport {
  box-shadow: inset 0px -1px 1px 0.6px #0000001f, -1px -1px 12px 0.6px #00000000;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.needs-margin {
  margin-top: 0.7em;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: solid;
  color: white;
  text-align: center;
}

.ks-stay-on-top {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    top: 125px;
  }
}

.ks-on-top {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    top: 125px;
  }
}

.ks-stay-on-top.ks-solid-bg {
  background: solid;
  background-color: $primaryTextColor;
}

.ks-stay-on-top-dialog {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}

.ks-sticky-footer {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  width: 100%;
  z-index: 100;
  //padding: $inputPadding;
  // background: solid;
  // background-color: $primaryTextColor;
  color: $primaryColor;
  // border: $containerHeaderBorder;
  .ui-progressbar {
    background-color: $primaryTextColor;
  }
  .ui-progressbar-indeterminate {
    background-color: $primaryTextColor;
  }
}

fieldset {
  box-shadow: 0px 0px 1px 0.1px #8d8d8d;
  border: none;
  padding-top: 0;
}

.p-datatable-scrollable-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.layout-wrapper .layout-sidebar .layout-menu li a {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.disabled-background {
  margin: 0.6rem;
  border-radius: 4px;
  width: -webkit-fill-available;
}

.disabled-background-dark {
  background-color: #313131;
}

.disabled-background-light {
  background-color: #f8f9fa;
}

.no-records {
  border: solid rgba(211, 211, 211, 0.03) 0.5px;
}

.ui-dropdown-label-container {
  width: 100%;
}
.ui-dropdown.ui-dropdown-clearable .ui-dropdown-label {
  text-overflow: ellipsis;
  padding-right: 3em !important;
}
.ui-dropdown .ui-dropdown-label {
  text-overflow: ellipsis;
  padding-right: 2em !important;
}

//Chrome, Safari e Opera
:-webkit-full-screen {
  background-color: $bodyBgColor;
  overflow: scroll;
}
//Firefox
:-moz-full-screen {
  background-color: $bodyBgColor;
  overflow: scroll;
}
//IE/Edge
:-ms-fullscreen {
  background-color: $bodyBgColor;
  overflow: scroll;
}
//Sintaxe Padrão
:fullscreen {
  background-color: $bodyBgColor;
  overflow: scroll;
}
